import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MonthlyPerformanceGraph from './MonthlyPerformanceGraph';

function ReportTab() {


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
            Order Requests completed Per Month
            </Typography>
          </Grid>
        </Grid>
        {/* <DriverOrderReportTable /> */}
        <MonthlyPerformanceGraph />
      </Container>
    </div>
  );
}

export default ReportTab;
