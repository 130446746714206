import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert'; // Import Alert component
import { JOBS_LOG_ENDPOINT, JOB_EXECUTE_ENDPOINT } from '../../config/urls';
import { useAuth } from "../../hooks/AuthProvider";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'; // For loading indicator
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

const columns = [
    { id: 'Name', label: 'Cron Name' },
    { id: 'Total Order Notifications', label: 'Total Order Notifications'},
    { id: 'LastExecuted', label: 'Last Executed' },
    { id: 'Delivery Date', label: 'Delivery Date' },
    { id: 'Action', label: 'Excuted By' },
];

function JobsTable({ dateInput, searchInput, refreshKey, setRefreshKey }) {
    const [rowCount, setRowCount] = useState(0);
    const { getValidToken } = useAuth();
    const auth = useAuth();
    const navigate = useNavigate();

    const [jobsData, setJobsData] = useState([]);

    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 20
    });

    // State for loading indicator
    const [loadingJobs, setLoadingJobs] = useState(false);

    // States for confirmation dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState(null);

    // State for global alert messages
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success', // 'success' or 'error'
    });

    useEffect(() => {
        let searchURL = '';

        if (searchInput) {
            searchURL = `&search=${encodeURIComponent(searchInput)}`;
        }

        const fetchData = async () => {
            setLoadingJobs(true);
            try {
                const token = await getValidToken();

                const headers = {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                };
                const response = await fetch(
                    `${JOBS_LOG_ENDPOINT}?limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}${searchURL}`, 
                    { headers }
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    if (errorData.code === "user_inactive") {
                        auth.logOut();  // Immediately logs out the user
                        navigate('/login');
                    }

                    throw new Error(errorData.detail || 'Failed to fetch data');
                }
                const jsonData = await response.json();
                console.log('Fetched data:', jsonData); // Debugging line

                // Ensure jobsData is always an array
                setJobsData(Array.isArray(jsonData.results) ? jsonData.results : []);
                setRowCount(jsonData.count || 0);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                setAlert({ open: true, message: error.message, severity: 'error' });
            } finally {
                setLoadingJobs(false);
            }
        };
        fetchData();
    }, [controller, refreshKey, searchInput, getValidToken, auth, navigate]);

    const handlePageChange = (event, newPage) => {
        setController({
            ...controller,
            page: newPage
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setController({
            ...controller,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
    };

    const handleRunClick = (jobId) => {
        setSelectedJobId(jobId);
        setOpenDialog(true);
    };

    const confirmRun = async () => {
        setOpenDialog(false);
        if (selectedJobId) {
            await handleRunJob(selectedJobId);
        }
    };

    const cancelRun = () => {
        setOpenDialog(false);
        setSelectedJobId(null);
    };

    const handleRunJob = async (jobId) => {
        try {
            const token = await getValidToken();

            const headers = {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            };

            const response = await fetch(
                `${JOB_EXECUTE_ENDPOINT}`, 
                {
                    method: 'POST',
                    headers
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.code === "user_inactive") {
                    auth.logOut();  // Immediately logs out the user
                    navigate('/login');
                }
                throw new Error(errorData.detail || 'Failed to run job');
            }

            const jsonData = await response.json();
            const { message } = jsonData;

            setAlert({ open: true, message, severity: 'success' });
            // Optionally, refresh the table data to update Last Executed
            setRefreshKey(oldKey => oldKey + 1);
        } catch (error) {
            console.error('Error running job:', error.message);
            setAlert({ open: true, message: error.message, severity: 'error' });
        }
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', mt: 4 }}>
            <Box sx={{ p: 2 }}>
            <Typography variant="h5" component="h5" gutterBottom>
                    Cron Logs
                </Typography>
                {alert.open && (
                    <Alert 
                        onClose={handleCloseAlert} 
                        severity={alert.severity} 
                        sx={{ mb: 2 }}
                    >
                        {alert.message}
                    </Alert>
                )}
            </Box>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="jobs table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="left"
                                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    <h3>{column.label}</h3>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobsData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No match found
                                </TableCell>
                            </TableRow>
                        ) : (
                            jobsData.map((job, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{job.job?.name || "-"}</TableCell>
                                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{job.assigned_orders}</TableCell>
                                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{dayjs(job.job_executed_at).format("MMMM D, YYYY, h:mm A")}</TableCell>
                                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{job.delivery_date ? dayjs(job.delivery_date).format("DD/MM/YYYY") : "-"}</TableCell>
                                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{job.job_executed_by?.name || "-"}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[20]}
                component="div"
                count={rowCount}
                rowsPerPage={controller.rowsPerPage}
                page={controller.page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            
        </Paper>
    );
}

export default JobsTable;
