import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ZoneOrderReportTable from './ReportTable';
import ZoneSearchInput from "./SearchInput";


function OrdersTab() {
    const [searchValue, setSearchValue] = useState("");
    
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Order Requests Per Zone
            </Typography>
          </Grid>
          <Grid item>
            <ZoneSearchInput updateSearchValue={updateSearchValue} />
          </Grid>
        </Grid>
        <ZoneOrderReportTable searchInput={searchValue} />
      </Container>
    </div>
  );
}

export default OrdersTab;
