import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';

import { AUDIENCE_GET_ENDPOINT } from '../../../config/urls';

const SelectSegment = ({
        campaignName, setCampaignName,
        campaignDescription, setCampaignDescription,
        selectedSegment, setSelectedSegment,
        phoneNumberList, setPhoneNumberList
    }) => {
    
    const [audienceData, setAudienceData] = useState([]);

    const [allSegments, setAllSegments] = useState([]);
    const [activeSegments, setActiveSegments] = useState([]);

    const [allSegmentsDetails, setAllSegmentsDetails] = useState([]);
    const [allActiveSegmentsDetails, setAllActiveSegmentsDetails] = useState([]);

    const [allZonesDetails, setAllZonesDetails] = useState([]);

    const handleSegmentChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSegment(selectedValue);

        let numberList = audienceData.reduce((acc, customer) => {
        if (customer.segments.includes(selectedValue)) {
            acc.push(`${customer.country_code}${customer.phone_no}`);
        }
        return acc;
        }, []);
        
        // console.log("numberList: ", numberList);
        setPhoneNumberList(numberList);
    };

    const handleNameChange = (event) => {
        const newName = event.target.value;
        
        if (newName === '' || newName.trim().length > 0) {
            if (newName.length <= 50) {
                setCampaignName(newName);
            }
        }
    };
    
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        
        if (newDescription === '' || newDescription.trim().length > 0) {
            if (newDescription.length <= 250) {
                setCampaignDescription(newDescription);
            }
        }
    };
    
    const fetchData = async () => {
        try {
            const response = await fetch(AUDIENCE_GET_ENDPOINT);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const jsonData = await response.json();
        
            console.log("GET API response: ", jsonData);
        
            setAudienceData(jsonData.results);
        
            setAllSegments(jsonData.all_segments_details.map(segment => segment.segment_name));
            setActiveSegments(jsonData.all_segments_details.filter(segment => segment.active === true).map(segment => segment.segment_name));
            setAllSegmentsDetails(jsonData.all_segments_details);
            setAllActiveSegmentsDetails(jsonData.all_segments_details.filter(segment => segment.active === true));
        
            setAllZonesDetails(jsonData.all_zones_details);
        
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedSegment, phoneNumberList]);
    
    return (
        <Grid container spacing={2} alignItems="center" sx={{mt: 1}}>
            {/* Campaign Name */}
            <div style={{ position: 'relative', width: '90%', marginTop: 2, marginBottom: 2 }}>
                
                <Typography variant="h6" sx={{ marginBottom: 1, mt: 2 }}>
                    Campaign Name *
                </Typography>
                <TextField
                    label="Campaign Name"
                    value={campaignName}
                    onChange={handleNameChange}
                    fullWidth
                    required
                />
                <Typography 
                    variant="body2" 
                    sx={{ 
                        color: 'gray', 
                        position: 'absolute', 
                        right: '10px', 
                        top: '10px',
                        pointerEvents: 'none'
                    }}>
                    {`${campaignName.length}/50`}
                </Typography>
            </div>

            {/* Campaign Description */}
            <div style={{ position: 'relative', width: '90%', marginTop: 2, marginBottom: 2 }}>
                
                <Typography variant="h6" sx={{ marginBottom: 1, mt: 2 }}>
                    Campaign Description
                </Typography>
                <TextField
                    label="Campaign Description"
                    multiline
                    rows={4}
                    value={campaignDescription}
                    onChange={handleDescriptionChange}
                    fullWidth
                />
                <Typography 
                    variant="body2" 
                    sx={{ 
                        color: 'gray', 
                        position: 'absolute', 
                        right: '10px', 
                        top: '10px',
                        pointerEvents: 'none'
                    }}>
                    {`${campaignDescription.length}/250`}
                </Typography>
            </div>
            
            <div>
                <Typography variant="h6" sx={{ marginBottom: 1, mt: 2 }}>
                    Select Segment *
                </Typography>
                <Select 
                    value={selectedSegment} 
                    onChange={handleSegmentChange}
                    fullWidth
                    sx={{ mt: 1 }}
                >
                    <MenuItem value="Select a segment">
                        Select a segment
                    </MenuItem>
                    {activeSegments.map((segment, index) => (
                    <MenuItem 
                        key={index} 
                        value={segment}
                    >
                        {segment}
                    </MenuItem>
                    ))}
                </Select>
            </div>
        </Grid>
    );
};
export default SelectSegment;