import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Collapse,
  Alert,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { VANS_CREATE_ENDPOINT, VANS_EDIT_ENDPOINT, ZONES_ENDPOINT, DRIVERS_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const VanFormDialog = ({ modalState, handleModalState, selectedClient, refreshTable }) => {
  const { getValidToken } = useAuth(); 
  const [alertObj, setAlertObj] = useState({ active: false, msg: "", type: "" });
  
  const [formData, setFormData] = useState({
    name: '',
    zone: '', 
    driver: '', 
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    zone: '',
    driver: '', 
  });

  const [zones, setZones] = useState([]); 
  const [drivers, setDrivers] = useState([]); 

  const handleAlertClose = () => setAlertObj({ active: false, type: "", msg: "" });

  const handleClose = () => {
    handleModalState({ isOpen: false });
    setFormErrors({ name: '', zone: '', driver: '' });
    setFormData({ name: '', zone: '', driver: '' });
  };

  // Fetch zones using Fetch API
  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await fetch(ZONES_ENDPOINT);
        const data = await response.json();
        setZones(data.results);
      } catch (error) {
        console.error('Failed to fetch zones:', error);
      }
    };
    fetchZones();
  }, []);

  // Fetch drivers using Fetch API
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const token = await getValidToken(); 
        const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
        const response = await fetch(DRIVERS_ENDPOINT, { headers });
        const data = await response.json();
        setDrivers(data.results);
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
      }
    };
    fetchDrivers();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      setFormData({
        name: selectedClient.name || '',
        zone: selectedClient.zone?.id || '', 
        driver: selectedClient.driver?.id || '', 
      });
    } else {
      setFormData({ name: '', zone: '', driver: '' });
    }
    setFormErrors({ name: '', zone: '', driver: '' });
  }, [selectedClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newFormErrors = {};
    let hasErrors = false;

    if (!formData.name.trim()) {
      newFormErrors.name = 'Please enter a name';
      hasErrors = true;
    }
    if (!formData.zone) {  
      newFormErrors.zone = 'Please select a zone';
      hasErrors = true;
    }
    if (!formData.driver) {  
      newFormErrors.driver = 'Please select a driver';
      hasErrors = true;
    }

    setFormErrors(newFormErrors);
    return !hasErrors;
  };

  const handleAdd = async () => {
    if (!validateForm()) return;

    try {
      const token = await getValidToken();
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      // Prepare data to send
      const payload = {
        name: formData.name,
        zone: formData.zone,
        driver: formData.driver, 
      };

      const response = await fetch(VANS_CREATE_ENDPOINT, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setAlertObj({ active: true, type: "success", msg: "Van created successfully" });
        refreshTable(prev => prev + 1);
        handleClose();
      } else {
        const errorData = await response.json();
        setFormErrors({
          name: errorData.name || '',
          zone: errorData.zone || '',
          driver: errorData.driver || '',
        });
        setAlertObj({ active: true, type: "error", msg: "Failed to create driver" });
      }
    } catch (error) {
      console.error('Failed to create driver:', error);
      setAlertObj({ active: true, type: "error", msg: "Failed to create driver" });
    }
  };

  const handleEdit = async () => {
    if (!validateForm()) return;

    try {
      const token = await getValidToken();
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      // Prepare data to send
      const payload = {
        name: formData.name,
        zone: formData.zone,
        driver: formData.driver, 
      };

      const response = await fetch(`${VANS_EDIT_ENDPOINT}${selectedClient.id}/`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setAlertObj({ active: true, type: "success", msg: "Van updated successfully" });
        refreshTable(prev => prev + 1);
        handleClose();
      } else {
        const errorData = await response.json();
        setFormErrors({
          name: errorData.name || '',
          zone: errorData.zone || '',
          driver: errorData.driver || '',
        });
        setAlertObj({ active: true, type: "error", msg: "Failed to update driver" });
      }
    } catch (error) {
      console.error('Failed to update driver:', error);
      setAlertObj({ active: true, type: "error", msg: "Failed to update driver" });
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {selectedClient ? "Edit Van" : "Add Van"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert severity={alertObj.type} onClose={handleAlertClose}>
            {alertObj.msg}
          </Alert>
        </Collapse>
        <Box sx={{ m: 3 }}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
            required
          />
          <FormControl fullWidth margin="normal" error={!!formErrors.zone}>
            <InputLabel id="zone-select-label">Zone</InputLabel>
            <Select
              labelId="zone-select-label"
              name="zone"
              value={formData.zone}
              onChange={handleChange}
              label="Zone"
              required
            >
              {zones.map(zone => (
                <MenuItem key={zone.id} value={zone.id}>{zone.name}</MenuItem>
              ))}
            </Select>
            {formErrors.zone && <FormHelperText>{formErrors.zone}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!formErrors.driver}>
            <InputLabel id="driver-select-label">Driver</InputLabel>
            <Select
              labelId="driver-select-label"
              name="driver"
              value={formData.driver}
              onChange={handleChange}
              label="Driver"
              required
            >
              {drivers.map(driver => (
                <MenuItem key={driver.id} value={driver.id}>{driver.name}</MenuItem>
              ))}
            </Select>
            {formErrors.driver && <FormHelperText>{formErrors.driver}</FormHelperText>}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>Cancel</Button>
        <Button onClick={selectedClient ? handleEdit : handleAdd}>
          {selectedClient ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default VanFormDialog;
