import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { USERS_ENDPOINT } from '../../config/urls';
import UserFormDialog from './UserFormDialog';
import { useAuth } from "../../hooks/AuthProvider";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';


const columns = [

    { id: 'Name', label: 'Name' },
    { id: 'Email', label: 'Email' },
    { id: 'Status', label: 'Status' },
    { id: 'Created At', label: 'Created At' },
    { id: 'Action', label: 'Action' },
  ];

function UsersTable({ dateInput, searchInput }) {
  const [rowCount, setRowCount] = useState(0);
  const { getValidToken } = useAuth();
  const auth = useAuth();
  const navigate = useNavigate();

  const [usersData, setUsersData] = useState([]);
  const [userModalState, setUserModalState] = useState({ isOpen: false, selectedUser: null, mode: 'add' });

  const [refreshKey, setRefreshKey] = useState(0);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  useEffect(() => {
    let searchURL = '';

    if (searchInput) {
      searchURL = `&search_id=${searchInput}`;
    }

    const fetchData = async () => {
      try {
        const token = await getValidToken();

        const headers = {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        };
        const response = await fetch(
            USERS_ENDPOINT + `?limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}` + searchURL, { headers }
        );

        if (!response.ok) {
          const errorData = await response.json();
          if (errorData.code === "user_inactive") {
            
            auth.logOut();  // Immediately logs out the user
            navigate('/login');
          }

          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setUsersData(jsonData.results);
        setRowCount(jsonData.count);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchData();
  }, [controller, refreshKey, searchInput]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const handleEditClick = (user) => {
    console.log("User Data", user);
    setUserModalState({ isOpen: true, selectedUser: user, mode: 'edit' });
  };

  const handleAddClick = () => {
    setUserModalState({ isOpen: true, selectedUser: null, mode: 'add' });
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
      <Button variant="contained" onClick={handleAddClick} sx={{ margin: 2}}>Add User</Button>
    </Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No match found
                </TableCell>
              </TableRow>
            ) : (
              usersData.map((user, index) => (
                <TableRow key={index}>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{user.name}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{user.email}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                        {user.is_active ? 
                        <Chip variant="outlined" color="success" label="active" size="small" /> : 
                        <Chip variant="outlined" color="error" label="inactive" size="small" />
                        }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{dayjs(user.created_at).format("DD/MM/YYYY")}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Button variant="contained" onClick={() => handleEditClick(user)}>Edit</Button>
                    </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <UserFormDialog
        modalState={userModalState}
        handleModalState={setUserModalState}
        selectedUser={userModalState.selectedUser}
        refreshTable={setRefreshKey}
      />
    </Paper>
  );
}

export default UsersTable;