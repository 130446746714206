import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { VANS_ENDPOINT } from '../../config/urls';
import { useAuth } from "../../hooks/AuthProvider";
import { useNavigate } from 'react-router-dom';
import VanFormDialog from "./VanFormDialog";

const columns = [
  { id: 'Van', label: 'Van' },
  { id: 'zone', label: 'Zone' },
  { id: 'Driver', label: 'Driver' },
  { id: 'Updated By', label: 'Updated By' },
  { id: 'Updated On', label: 'Updated On' },
  { id: 'Action', label: 'Action' },
];

export default function UsersTable({ searchInput }) {
  const { getValidToken } = useAuth();
  const auth = useAuth();
  const navigate = useNavigate();

  const [rowCount, setRowCount] = useState(0);
  const [vansData, setVansData] = useState([]);
  const [vanModalState, setVanModalState] = useState({ isOpen: false, selectedClient: null, mode: 'add' });
  const [refreshKey, setRefreshKey] = useState(0);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20,
  });

  useEffect(() => {
    const fetchData = async () => {
      let searchURL = searchInput ? `&search_id=${encodeURIComponent(searchInput)}` : '';

      try {
        const token = await getValidToken();
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        const response = await fetch(
          `${VANS_ENDPOINT}?limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}${searchURL}`,
          { headers }
        );

        if (!response.ok) {
          let errorData;
          try {
            errorData = await response.json();
          } catch (err) {
            console.error('Error parsing response:', err);
            throw new Error('Failed to fetch data');
          }

          if (errorData.code === "user_inactive") {
            auth.logOut();
            navigate('/login');
          }

          throw new Error(errorData.message || 'Failed to fetch data');
        }

        const jsonData = await response.json();
        setVansData(jsonData.results || []);
        setRowCount(jsonData.count || 0);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, [controller, refreshKey, searchInput, getValidToken, auth, navigate]);

  const handlePageChange = (event, newPage) => {
    setController((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleEditClick = (van) => {
    setVanModalState({ isOpen: true, selectedClient: van, mode: 'edit' });
  };

  const handleAddClick = () => {
    setVanModalState({ isOpen: true, selectedClient: null, mode: 'add' });
  };

  const handleModalClose = () => {
    setVanModalState({ isOpen: false, selectedClient: null, mode: 'add' });
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <Button variant="contained" onClick={handleAddClick}>
          Add Van
        </Button>
      </Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="vans table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vansData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No match found
                </TableCell>
              </TableRow>
            ) : (
              vansData.map((van, index) => (
                <TableRow key={index}>
                  <TableCell align="left" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{van.name}</TableCell>
                  <TableCell align="left" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{van?.zone?.name || 'N/A'}</TableCell>
                  <TableCell align="left" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{van.driver?.name || 'N/A'}</TableCell>
                  <TableCell align="left" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{van.updated_by?.name || 'N/A'}</TableCell>
                  <TableCell align="left" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {van.updated_at ? dayjs(van.updated_at).format("DD/MM/YYYY") : 'N/A'}
                  </TableCell>
                  <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Button variant="contained" onClick={() => handleEditClick(van)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {vanModalState.isOpen && (
        <VanFormDialog
          modalState={vanModalState}
          handleModalState={handleModalClose}
          refreshTable={setRefreshKey}
        />
      )}
    </Paper>
  );
}
