import { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Alert, InputAdornment, CircularProgress, Collapse } from '@mui/material';
import { useAuth } from "../hooks/AuthProvider";
import Logo from "./Logo.js";
import Person from '@mui/icons-material/Person';
import axios from 'axios';
import Footer from './Footer.js';
import { LOGIN_ENDPOINT } from '../config/urls';

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '90vh',
};

const formHeaderStyle = {
  marginBottom: '10px', 
};

const formStyle = {
  marginTop: '30px',
};

const formContainerStyle = {
  width: '300px',
  padding: '60px',
  border: '1px solid #ccc',
  borderRadius: '10px',
};

const formGroupStyle = {
  marginBottom: '20px',
};

function checkString(string) {
  return /^[0-9]{6}$/.test(string);
}

const Login = () => {
  const [input, setInput] = useState({
    email: "",
    key: "",
    otp: ""
  });

  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [message, setMessage] = useState('OTP sent on your email.');
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleGenerateOTP = async (event) => {
    event.preventDefault();
    
    if (email.trim() === '') {
      setError(true);
      setMessage('Enter a valid email address.');
      return;
    }

    setLoading(true);
    const contentType = { 'Content-Type': 'application/json' };
    
    try {
      const loginUrl = LOGIN_ENDPOINT;
      const response = await axios.post(loginUrl, { email }, contentType);
      setOtpSent(true);
      setError(false);
      sessionStorage.setItem("key", response.data.key);
      setMessage(response.data.msg);
      setTimer(60);  // Set timer to 60 seconds
    } catch (error) {
      console.error(error);
      setOtpSent(false);
      setError(true);
      setMessage('Invalid email, please contact administrator.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and limit the length to 6
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      setError(false);
      setMessage('');
    } else {
      setError(true);
      setMessage('please enter a 6-digit numeric OTP.');
    }
  };

  const handleResendOTP = async (event) => {
    event.preventDefault();

    if (email.trim() === '') {
      setError(true);
      setMessage('please enter a valid email address.');
      return;
    }

    setLoading(true);
    const contentType = { 'Content-Type': 'application/json' };

    try {
      const loginUrl = LOGIN_ENDPOINT;
      const response = await axios.post(loginUrl, { email }, contentType);
      setError(false);
      sessionStorage.setItem("key", response.data.key);
      setMessage(response.data.message);
      setOpen(true);
      setTimer(60);  // Reset timer to 60 seconds
    } catch (error) {
      console.error(error);
      setError(true);
      setMessage('Failed to resend OTP, please contact administrator.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    if (otp.trim() === '') {
      setError(true);
      setMessage('OTP required.');
      return;
    } else if (!checkString(otp)){
      setError(true);
      setMessage('Enter a 6-digit OTP.');
      return;
    } else {
      setError(false);
      setMessage("");
    }

    setLoading(true);

    try {
      const sessionKey = sessionStorage.getItem('key');
      input.key = sessionKey;
      input.otp = otp;
      let actionLogin = auth.loginAction(input);
      actionLogin.then(function(result){
        if (result.type === "error"){
          setOtpSent(true);
          setError(true);
          setMessage('Invalid OTP.');
        }
        else{
          setOtpSent(true);
          setError(false);
          setIsLoggedIn(true);
        }
      });
      
    } catch (error) {
      console.error(error);
      setOtpSent(true);
      setError(true);
      setMessage('Invalid OTP.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container style={containerStyle}>
        <div style={formContainerStyle}>
          <Logo />

          <Typography variant="h5" style={formHeaderStyle} gutterBottom>
            {otpSent ? 'Verify OTP' : 'Login'}
          </Typography>
          <hr />

          {otpSent ? (
            <form style={formStyle} onSubmit={handleVerifyOTP}>
              { otpSent && <Collapse in={open}><Alert style={formGroupStyle} onClose={() => {setOpen(false);}} severity="success">OTP sent on your email</Alert></Collapse>}
              <div style={formGroupStyle}>
                <TextField
                  id="otp"
                  name="otp"
                  label="Enter OTP"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={otp}
                  error={error}
                  helperText={error ? message : ""}
                  onChange={handleChange}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                Verify OTP
              </Button>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={handleResendOTP}
                disabled={timer > 0 || loading}
                sx={{
                  '&:disabled': {
                    color: 'rgba(0, 0, 0, 0.48)', // Optional: Adjust the color when the button is disabled
                  },
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : `Resend OTP ${timer > 0 ? `In (${timer} sec)` : ''}`}
              </Button>
            </form>
          ) : (
            <form onSubmit={handleGenerateOTP} style={formStyle}>
              <div style={formGroupStyle}>
                <TextField
                  id="email"
                  name="email"
                  label="Business Email"
                  variant="outlined"
                  size="small"
                  type="email"
                  fullWidth
                  error={error}
                  helperText={error ? message : ""}
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                fullWidth
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Send OTP'} 
              </Button>
            </form>
          )}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Login;

