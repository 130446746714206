import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  Collapse,
  Typography,
  Alert,
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { CUSTOMERS_CREATE_ENDPOINT, CUSTOMERS_EDIT_ENDPOINT } from '../../config/urls';
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CustomerFormDialog = ({ modalState, handleModalState, selectedCustomer, refreshTable }) => {
  const { getValidToken } = useAuth();
  const [cleared, setCleared] = useState(false);
  const [datetimeArray, setDateTimeArray] = useState(['day', 'month', 'year']);

  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: ""
  });

  const [formData, setFormData] = useState({
    name: '',
    phone_no: '',
    email: '',
    date_of_birth: null,
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    phone_no: '',
    email: '',
    date_of_birth: '',
  });

  const handleAlertClose = () => {
    setAlertObj({
      active: false,
      type: "",
      msg: ""
    });
  };

  const handleClose = () => {
    handleModalState({ isOpen: false });
    setAlertObj({ active: false, msg: '', type: '' });
    setFormErrors({
      name: '',
      phone_no: '',
      email: '',
      date_of_birth: '',
    });
  };

  useEffect(() => {
    if (selectedCustomer) {
      setFormData({
        name: selectedCustomer.name,
        phone_no: selectedCustomer.phone_no,
        email: selectedCustomer.email || '',
        date_of_birth: selectedCustomer.date_of_birth ? dayjs(selectedCustomer.date_of_birth) : null,
      });
    } else {
      setFormData({
        name: '',
        phone_no: '',
        email: '',
        date_of_birth: null,
      });
    }
  }, [selectedCustomer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date_of_birth: date });
  };

  const validateForm = async () => {
    const newFormErrors = {};
    let hasErrors = false;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (formData.name.trim() === '' || formData.name.length > 250) {
      newFormErrors.name = 'Please enter a name (max 250 characters)';
      hasErrors = true;
    }

    if (formData.phone_no.trim() === '') {
      newFormErrors.phone_no = 'Please enter a phone number';
      hasErrors = true;
    }

    if (formData.email && !emailPattern.test(formData.email.trim())) {
      newFormErrors.email = 'Please enter a valid email';
      hasErrors = true;
    }

    setFormErrors(newFormErrors);
    return !hasErrors;
  };

  const handleAdd = async () => {
    if (!(await validateForm())) {
      return;
    }

    const formattedData = {
      ...formData,
      date_of_birth: formData.date_of_birth ? dayjs(formData.date_of_birth).format('YYYY-MM-DD') : null,
    };

    const token = await getValidToken();

    axios.post(CUSTOMERS_CREATE_ENDPOINT, formattedData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setAlertObj({
          active: true,
          type: "success",
          msg: "Customer created successfully"
        });
        refreshTable(key => key + 1);
      })
      .catch(error => {
        const errorResponse = error.response?.data || {};
        const newFormErrors = {
          name: errorResponse.name || '',
          phone_no: errorResponse.phone_no || '',
          email: errorResponse.email || '',
          date_of_birth: errorResponse.date_of_birth || '',
        };
        setFormErrors(newFormErrors);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to create customer"
        });
      });
  };

  const handleEdit = async () => {
    if (!(await validateForm())) {
      return;
    }

    const token = await getValidToken();

    const formattedData = {
      ...formData,
      date_of_birth: formData.date_of_birth ? dayjs(formData.date_of_birth).format('YYYY-MM-DD') : null,
    };

    axios.patch(`${CUSTOMERS_EDIT_ENDPOINT}${selectedCustomer.id}/`, formattedData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setAlertObj({
          active: true,
          type: "success",
          msg: "Customer updated successfully"
        });
        refreshTable(key => key + 1);
      })
      .catch(error => {
        const errorResponse = error.response?.data || {};
        const newFormErrors = {
          name: errorResponse.name || '',
          phone_no: errorResponse.phone_no || '',
          email: errorResponse.email || '',
          date_of_birth: errorResponse.date_of_birth || '',
        };
        setFormErrors(newFormErrors);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to update customer"
        });
      });
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
        {modalState.mode === 'edit' ? 'Edit Customer' : 'Create Customer'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert sx={{ mb: 1 }} severity={alertObj.type} onClose={handleAlertClose}>
            {alertObj.msg}
          </Alert>
        </Collapse>
        <FormControl>
          <Box sx={{ m: 2 }}>
            <TextField
              fullWidth
              margin="normal"
              label="Name *"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Phone Number *"
              name="phone_no"
              value={formData.phone_no}
              onChange={handleChange}
              error={!!formErrors.phone_no}
              helperText={formErrors.phone_no}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
              sx={{ mb: 1 }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <Box>
                <DemoItem label="" sx={{ mt: 1 }}>
                  <DatePicker
                    label="Date Of Birth"
                    format="DD/MM/YYYY"
                    value={formData.date_of_birth}
                    onChange={(newValue) => handleDateChange(newValue)}
                    disableFuture
                    views={datetimeArray}
                    slotProps={{
                      field: { clearable: true, onClear: () => setCleared(true) },
                    }}
                    error={!!formErrors.date_of_birth}
                    helperText={formErrors.date_of_birth}
                  />
                </DemoItem>
              </Box>
            </LocalizationProvider>

            
             {/* Display Addresses in Edit Mode as Cards */}
             {modalState.mode === 'edit' && selectedCustomer.addresses.length > 0 && (
               <Box sx={{ mt: 4 }}>
                 <Typography variant="h6" gutterBottom>
                   Addresses
                 </Typography>
                 <Grid container spacing={2}>
                   {selectedCustomer.addresses.map((address) => (
                     <Grid item xs={12} sm={12} key={address.id}>
                       <Card variant="outlined">
                         <CardContent>
                           <Typography variant="subtitle1" component="div" gutterBottom>
                             {address.name}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Latitude:</strong> {address.latitude}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Longitude:</strong> {address.longitude}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Zone:</strong> {address.zone}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Subzone:</strong> {address.subzone}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Appartment / Vila Details:</strong> {address.appartment_details || "-"}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Buiding Details:</strong> {address.building_details || "-"}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Street Details:</strong> {address.street_details || "-"}
                           </Typography>
                           <Typography variant="body2" color="textSecondary">
                             <strong>Landmark:</strong> {address.landmark_details || "-"}
                           </Typography>
                           {/* <Typography variant="body2" color="textSecondary">
                             <strong>details:</strong> {address.full_address}
                           </Typography> */}
                         </CardContent>
                       </Card>
                     </Grid>
                   ))}
                 </Grid>
               </Box>
             )}

          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        {modalState.mode === 'edit' ? (
          <Button onClick={handleEdit} color="primary" variant="contained">
            Update
          </Button>
        ) : (
          <Button onClick={handleAdd} color="primary" variant="contained">
            Add
          </Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CustomerFormDialog;
