import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// import DriverSearchInput from "../DriverSearchInput";
import JobsTable from './JobsTable';
import JobsLogTable from './JobsLogTable';

function UsersTab(props) {
    const [searchValue, setSearchValue] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);
    
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Cron
            </Typography>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
        <JobsTable refreshKey={refreshKey} setRefreshKey={setRefreshKey} />
        <JobsLogTable refreshKey={refreshKey} />
      </Container>
    </div>
  );
}

export default UsersTab;
