import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// import ZoneSearchInput from "../ZoneSearchInput";
import ZonesTable from './ZonesTable';

function ZonesTab() {
    const [searchValue, setSearchValue] = useState("");
    
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Zones
            </Typography>
          </Grid>
          <Grid item>
            {/* <ZoneSearchInput updateSearchValue={updateSearchValue} /> */}
          </Grid>
        </Grid>
        <ZonesTable searchInput={searchValue} />
      </Container>
    </div>
  );
}

export default ZonesTab;
