import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Grid,
    TextField,
    FormControl,
    InputLabel,
} from '@mui/material';

const SingleContact = (props) => {
    const setPhoneNumber = props.setPhoneNumber;

    const [countryCode, setCountryCode] = useState('');
    const [phoneNumberSuffix, setPhoneNumberSuffix] = useState('');

    const handleConcatenation = () => {
        if (countryCode && phoneNumberSuffix) {
            setPhoneNumber(`${countryCode}${phoneNumberSuffix}`);
        }
    }

    useEffect(() => {
        handleConcatenation();
    }, [countryCode, phoneNumberSuffix]);
    
    return (
        <Grid container spacing={2} alignItems="center" sx={{mt: 1}}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Country Code *</InputLabel>
                    <Select
                        label="Country Code"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        fullWidth
                        required
                    >
                        <MenuItem value="">Select Country Code</MenuItem>
                        <MenuItem value="91">IND (+91)</MenuItem>
                        <MenuItem value="1">US (+1)</MenuItem>
                        <MenuItem value="89">CHN (+89)</MenuItem>
                        <MenuItem value="61">AU (+61)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    sx={{ width: '80%' }}
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNumberSuffix}
                    onChange={(e) => setPhoneNumberSuffix(e.target.value)}
                    required
                />
            </Grid>
        </Grid>
    );
};
export default SingleContact;