import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import Container from '@mui/material/Container';

import BroadcastTemplates from "./BroadcasteTemplates";

const Broascast = (props) => {

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          <h2 className="heading-title">Broadcast Templates</h2>
          <BroadcastTemplates 
            refreshkey={props.refreshKey}
            onNavigate={props.onNavigate}
          />
        </>
      </Container>

    </div>
  );
}
export default Broascast;
