import AuthProvider from './hooks/AuthProvider';
import PrivateRoute from "./router/router";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import MainApplication from "./components/MainApplication";  // here MainApplication refers to BaseTemplate in FCB project
import LoginForm from "./components/Login";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<MainApplication />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
