import React, { useEffect } from "react";
import {
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    IconButton,
  } from '@mui/material';
import ButtonComponent from "./ButtonComponent";

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';

const countries = [
    { label: 'USA', value: 'USA' },
    { label: 'Canada', value: 'Canada' },
    { label: 'Mexico', value: 'Mexico' },
    // Add more countries as needed
];

const EditTemplate = ({
    templateType,
    templateName, setTemplateName, 
    language, setLanguage, 
    headerType, setHeaderType, 
    headerText, setHeaderText, 
    headerFile, setHeaderFile, 
    body, setBody, 
    sampleBodyFields, setsampleBodyFields, 
    sampleHeaderFields, setsampleHeaderFields,
    footer, setFooter,
    allButtons, setAllButtons,
    isButtonsValid, setIsButtonsValid,
    error, setError,
    templatesNamesData
}) => {
    // const [error, setError] = useState(false);

    const handleTemplateNameChange = (event) => {
        const newTemplateName = event.target.value
            .toLowerCase()
            .replace(/\s+/g, '_');

        setTemplateName(newTemplateName);
    };

    const detectPlaceholders = (text) => {
        const matches = text.match(/\{\{\d+\}\}/g) || []; // match {{number}} placeholders
        return matches.map((match, index) => ``); // create labels for each match
    };
    
    const handleBodyChange = (event) => {
        const newBody = event.target.value;
        
        if (newBody === '' || newBody.trim().length > 0) {
            if (newBody.length <= 1024) {
                setBody(newBody);
        
                const samples = detectPlaceholders(newBody);
                setsampleBodyFields(samples);
            }
        }
    };

    const handleHeaderChange = (event) => {
        const newHeader = event.target.value;

        if (newHeader === '' || newHeader.trim().length > 0) {
            if (newHeader.length <= 60) {
                setHeaderText(newHeader);
        
                const samples = detectPlaceholders(newHeader);
                setsampleHeaderFields(samples);
            }
        }
    };

    const handleFooterChange = (event) => {
        const newFooter = event.target.value;

        if (newFooter === '' || newFooter.trim().length > 0) {
            if (newFooter.length <= 60) {
                setFooter(newFooter);
            }
        }
    };
    
    const handleHeaderFileChange = (event) => {
        setHeaderFile(event.target.files[0]);
    };

    const handleDeleteHeaderFile = () => {
        setHeaderFile(null);
    };

    const getHeaderFileInput = () => {
        const fileTypes = {
            image: {
                accept: '.jpg, .png',
                label: 'Choose .JPG or .PNG',
            },
            video: {
                accept: '.mp4',
                label: 'Choose .MP4 file',
            },
            document: {
                accept: '.pdf',
                label: 'Choose .PDF file',
            },
        };
        if (fileTypes[headerType]) {
            const { accept, label } = fileTypes[headerType];
            return (
                <div>
                    <label htmlFor="file-upload">
                        <Button
                            variant="contained"
                            component="span"
                            sx={{ marginTop: 1, marginBottom: 1 }}
                        >
                            {label}
                        </Button>
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        accept={accept}
                        onChange={handleHeaderFileChange}
                        style={{ display: 'none' }}
                    />
                    {headerFile && (
                        <div>
                            <Typography variant="body2">
                                File uploaded: {headerFile.name}
                            </Typography>
                            <IconButton
                                aria-label="delete"
                                onClick={handleDeleteHeaderFile}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        const isDuplicate = templatesNamesData.some(template => template.toLowerCase() === templateName.toLowerCase());
        setError(isDuplicate);
    }, [templateName, templatesNamesData]);

    return (
        <div sx={{ padding: 2 }}>
            <Grid item xs={12} >
                <Grid container spacing={2} xs={12} md={20}>
                    <Grid item xs={12}>
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                        Template name and language
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={1} sm={5.4}>
                        <TextField
                            sx={{ width: '100%' }}
                            label="Template Name"
                            value={templateName}
                            onChange={handleTemplateNameChange}
                            InputProps={{
                                inputProps: {
                                    maxLength: 512,
                                },
                            }}
                            error={error}
                            helperText={error ? "Template name must be unique." : ""}
                            required
                        />
                        </Grid>
                        <Grid item xs={1} sm={5.4}>
                        <Select
                            sx={{ width: '100%' }}
                            // label="Language"
                            value={language}
                            onChange={(event) => setLanguage(event.target.value)}
                        >
                            <MenuItem value="english">English</MenuItem>
                            <MenuItem value="spanish">Spanish</MenuItem>
                            <MenuItem value="hindi">Hindi</MenuItem>
                            required
                        </Select>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Divider sx={{ width: '95%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                            Content
                        </Typography>

                        {/* Header */}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Header
                                    {headerType === 'none' && (
                                        <span style={{ opacity: 0.6 }}> (Optional)</span>
                                    )}
                                </Typography>
                                <Select
                                    sx={{ width: '90%' }}
                                    // label="Header Type"
                                    value={headerType}
                                    onChange={(event) => {
                                        const selectedHeaderType = event.target.value;
                            
                                        switch (selectedHeaderType) {
                                            case 'none':
                                                setHeaderText('');
                                                setHeaderFile(null);
                                                break;
                                            case 'text':
                                                setHeaderFile(null);
                                                break;
                                            case 'image':
                                            case 'video':
                                            case 'document':
                                                setHeaderText('');
                                                break;
                                            case 'location':
                                                setHeaderText('');
                                                setHeaderFile(null);
                                                break;
                                            default:
                                                break;
                                        }
                            
                                        setHeaderType(selectedHeaderType);
                                    }}
                                >
                                    <MenuItem value="none">None</MenuItem>
                                    <MenuItem value="text">
                                        <FormatColorTextIcon sx={{ marginRight: 1 }} color="primary" />Text
                                    </MenuItem>
                                    <MenuItem value="image">
                                        <ImageIcon sx={{ marginRight: 1}} color="primary" />Image
                                    </MenuItem>
                                    <MenuItem value="video">
                                        <VideocamIcon sx={{ marginRight: 1 }} color="primary" />Video
                                    </MenuItem>
                                    <MenuItem value="document">
                                        <PictureAsPdfIcon sx={{ marginRight: 1 }} color="primary" />Document
                                    </MenuItem>
                                    <MenuItem value="location">
                                        <LocationOnIcon sx={{ marginRight: 1 }} color="primary" />Location
                                    </MenuItem>
                                </Select>
                                {headerType === 'text' && (
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{ width: '90%', marginTop: 2, marginBottom: 2 }}
                                            label="Header Text"
                                            value={headerText}
                                            onChange={handleHeaderChange}
                                            // size="small"
                                            inputProps={{ 
                                                maxLength: 60,
                                                style: { paddingRight: '40px' }
                                            }} 
                                            InputProps={{
                                                endAdornment: (
                                                    <Typography 
                                                        variant="body2"
                                                        sx={{
                                                            color: 'gray',
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '12px'
                                                        }}
                                                    >
                                                        {`${headerText.length}/60`}
                                                    </Typography>
                                                ),
                                            }}
                                            required
                                        />
                                    </Grid>
                                )}
                                {getHeaderFileInput()}

                            </Grid>
                            {sampleHeaderFields.length > 0 && (
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Sample for header content
                                    </Typography>
                                    {sampleHeaderFields.map((sample, index) => (
                                    <div key={index} style={{ marginBottom: '16px' }}>
                                        <Typography variant="header1" sx={{ display: 'inline-block', marginRight: 1 }}>
                                            {'{{' + (index + 1) + '}} :'}
                                        </Typography>
                                        <TextField
                                        sx={{ width: '80%' }}
                                        value={sample}
                                        onChange={(event) => {
                                            const newSamples = [...sampleHeaderFields];
                                            newSamples[index] = event.target.value;
                                            setsampleHeaderFields(newSamples);
                                        }}
                                        required
                                        />
                                    </div>
                                    ))}
                                </Grid>
                            )}

                            <Divider sx={{ width: '95%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />

                            {/* Body */}
                            <Grid item xs={12}>
                                <div style={{ position: 'relative', width: '90%', marginTop: 2, marginBottom: 2 }}>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Body *
                                    </Typography>
                                    <textarea
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            paddingRight: '40px',
                                            boxSizing: 'border-box',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                        value={body}
                                        onChange={handleBodyChange}
                                        required
                                    />
                                    <Typography 
                                        variant="body2" 
                                        sx={{ 
                                            color: 'gray', 
                                            position: 'absolute', 
                                            right: '10px', 
                                            top: '10px',
                                            pointerEvents: 'none'
                                        }}>
                                        {`${body.length}/1024`}
                                    </Typography>
                                </div>
                            </Grid>
                            {sampleBodyFields.length > 0 && (
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Sample for body content
                                    </Typography>
                                    {sampleBodyFields.map((sample, index) => (
                                    <div key={index} style={{ marginBottom: '16px' }}>
                                        <Typography variant="body1" sx={{ display: 'inline-block', marginRight: 1 }}>
                                            {'{{' + (index + 1) + '}} :'}
                                        </Typography>
                                        <TextField
                                        sx={{ width: '80%' }}
                                        value={sample}
                                        onChange={(event) => {
                                            const newSamples = [...sampleBodyFields];
                                            newSamples[index] = event.target.value;
                                            setsampleBodyFields(newSamples);
                                        }}
                                        required
                                        />
                                    </div>
                                    ))}
                                </Grid>
                            )}

                            <Divider sx={{ width: '95%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />

                            {/* Footer */}
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Footer <span style={{ opacity: 0.6 }}>(Optional)</span>
                                </Typography>
                                <TextField
                                    sx={{ width: '90%', marginTop: 2, marginBottom: 2 }}
                                    label="Footer Text"
                                    value={footer}
                                    onChange={handleFooterChange}
                                    inputProps={{ 
                                        maxLength: 60,
                                        style: { paddingRight: '40px' }
                                    }} 
                                    InputProps={{
                                        endAdornment: (
                                            <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', right: '10px', top: '12px' }}>
                                                {`${footer.length}/60`}
                                            </Typography>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Divider sx={{ width: '95%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />

                            {/* Buttons */}
                            <Grid item xs={12}>
                                <ButtonComponent
                                    templateType={templateType}
                                    allButtons={allButtons}
                                    setAllButtons={setAllButtons}
                                    isButtonsValid={isButtonsValid}
                                    setIsButtonsValid={setIsButtonsValid}
                                    setFooter={setFooter}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
};
export default EditTemplate;
