import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import CampaignsTable from './CampaignsTable';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

const Campaigns = (props) => { 
  
  const [componentState, setComponent] = useState(1);

  const handleCreateTemplateClick = (newState) => {
    setComponent(newState);
  };

  return (
    <div className="App">
      <Container maxWidth={false}>
        <h2 className="heading-title">Campaigns</h2>
        <CampaignsTable 
            refreshkey={props.refreshKey} 
        />
      </Container>

    </div>
  );
}
export default Campaigns;
