import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import OrderSearchInput from "./OrderSearchInput";
import OrdersTable from './OrdersTable';

function OrdersTab() {
    const [searchValue, setSearchValue] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Order Requests
            </Typography>
          </Grid>
          <Grid item>
            <OrderSearchInput updateSearchValue={updateSearchValue} updateRefreshKey={setRefreshKey} />
          </Grid>
        </Grid>
        <OrdersTable searchInput={searchValue} refreshKey={refreshKey} />
      </Container>
    </div>
  );
}

export default OrdersTab;
