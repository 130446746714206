import { useState } from 'react';
import MiniDrawer from './Sidebar';

const MainApplication = (props) => {

  return (
    <>  
        <MiniDrawer />
    </>
  );
}
export default MainApplication;
