import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Typography, 
  Select, 
  MenuItem, 
  TextField, 
  Checkbox, 
  IconButton, 
  Tooltip, 
  ListSubheader,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Divider from '@mui/material/Divider';

const ButtonComponent = ({ 
  templateType,
  allButtons, setAllButtons,
  setIsButtonsValid,
  setFooter
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [tAndCChecked, setTAndCChecked] = useState(false);

  // console.log("allButtons", allButtons);
  
  // Track count of each button type (max: marketing-opt-out:1, custom:1, visit-website:2, call-phone-number:1, 'copy-code':1)
  const [buttonCounts, setButtonCounts] = useState({
    'marketing-opt-out': 0,
    'custom': 0,
    'visit-website': 0,
    'call-phone-number': 0,
    'copy-code': 0
  });

  // Reset function for button-specific states
  const resetButtonStates = (type) => {
    switch(type) {
      case 'marketing-opt-out':
        setTAndCChecked(false);
        setFooter('');
        break;
      // Add more cases for other button types
      default:
        break;
    }
  };

  // Check if total button limit (10) is reached
  const isButtonLimitReached = () => {
    return allButtons.length >= 10;
  };

  // Verify if more buttons of specific type can be added
  const canAddButtonType = (type) => {
    const limits = {
      'marketing-opt-out': 1,
      'custom': 10,
      'visit-website': 2,
      'call-phone-number': 1
    };
    return buttonCounts[type] < limits[type];
  };

  const hasDynamicPlaceholder = (url) => {
      return url.includes('{{1}}');
  };
  
  useEffect(() => {
    const marketingOptOutExists = allButtons.some(button => button.type === 'marketing-opt-out');
    if (marketingOptOutExists) {
      setIsButtonsValid(tAndCChecked);
    } else {
      setIsButtonsValid(true);
    }
  }, [allButtons, tAndCChecked, setIsButtonsValid]);

  // Enhanced option change handler to support multiple buttons
  const handleOptionChange = (event) => {
    const option = event.target.value;
    // Check both total limit and type-specific limit
    if (!canAddButtonType(option) || isButtonLimitReached()) {
      return;
    }
    setSelectedOption(option);
    
    // Create new button with unique ID and type-specific data structure
    let newButton = {
      id: Date.now(),
      type: option,
      data: {}
    };

    // Initialize button data based on type
    switch(option) {
      case 'marketing-opt-out':
        newButton.data = {
          buttonText: 'Stop promotions',
          footerText: 'Not interested? Tap on Stop promotions.'
        };
        break;
      case 'custom':
        newButton.data = {
          buttonText: ''
        };
        break;
      case 'visit-website':
        newButton.data = {
          buttonText: '',
          urlType: 'static',
          url: '',
          sampleUrl: ''
        };
        break;
      case 'call-phone-number':
        newButton.data = {
          buttonText: '',
          countryCode: '',
          phoneNumber: ''
        };
        break;
    }

    // Add new button and update count
    setAllButtons([...allButtons, newButton]);
    setButtonCounts({
      ...buttonCounts,
      [option]: buttonCounts[option] + 1
    });
    setSelectedOption('');
  };

  // Remove button and update type count
  const handleRemoveButton = (id, type) => {
    setAllButtons(allButtons.filter(button => button.id !== id));
    setButtonCounts({
      ...buttonCounts,
      [type]: buttonCounts[type] - 1
    });
    resetButtonStates(type);
  };

  // Centralized function to update button data
  const updateButtonData = (id, newData) => {
    setAllButtons(allButtons.map(button => 
      button.id === id ? { ...button, data: { ...button.data, ...newData } } : button
    ));
  };

  // URL validation
  const isValidURL = (url) => {
    const urlRegex = /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*))/g;
    return urlRegex.test(url);
  };

  const ButtonHeader = ({ allButtons }) => {
    return (
      <Grid item xs={12}>
        <Typography variant="h6">
          Buttons
          {allButtons.length === 0 && (
            <span style={{ opacity: 0.6 }}> (Optional)</span>
          )}
        </Typography>
      </Grid>
    );
  };

  const handleReorder = (buttonId, newPosition) => {
    setAllButtons(prevButtons => {
      const buttons = [...prevButtons];
      const currentIndex = buttons.findIndex(button => button.id === buttonId);
      const button = buttons[currentIndex];
      
      // Remove button from current position
      buttons.splice(currentIndex, 1);
      // Insert button at new position
      buttons.splice(newPosition, 0, button);
      
      return buttons;
    });
  };

  // Modular button rendering based on type
  const renderButton = (button) => {
    const currentIndex = allButtons.findIndex(b => b.id === button.id);

    const orderSlider = (
      <Grid item xs={0.5}>
        <Tooltip title="Drag to reorder">
          <IconButton
            sx={{
              cursor: 'move',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
            onMouseDown={(e) => {
              const currentIndex = allButtons.findIndex(b => b.id === button.id);
              const containerRect = e.currentTarget.parentElement.parentElement.getBoundingClientRect();
              const buttonHeight = containerRect.height;
              
              const handleMouseMove = (moveEvent) => {
                const deltaY = moveEvent.clientY - e.clientY;
                const newPosition = Math.round(currentIndex + deltaY / buttonHeight);
                if (newPosition >= 0 && newPosition < allButtons.length) {
                  handleReorder(button.id, newPosition);
                }
              };
              
              const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
              };
              
              document.addEventListener('mousemove', handleMouseMove);
              document.addEventListener('mouseup', handleMouseUp);
            }}
          >
            <DragIndicatorIcon sx={{ color: 'grey.500' }} />
          </IconButton>
        </Tooltip>
      </Grid>
    );

    switch(button.type) {
      case 'marketing-opt-out':
        setFooter(button.data.footerText);
        return (
          <>
            <Divider sx={{ width: '40%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />
            <Grid container spacing={2} sx={{ marginTop: 2, position: 'relative' }} key={button.id}>
              {orderSlider}
              <Grid item xs={2}>
                <TextField
                  label="Type"
                  value="marketing-opt-out"
                  disabled
                  fullWidth={false}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Button text"
                  value={button.data.buttonText}
                  disabled
                  fullWidth={false}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Footer text"
                  value={button.data.footerText}
                  disabled
                  fullWidth={false}
                />
              </Grid>
              <Grid 
                item 
                sx={{ 
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 'auto'
                }}
              >
                <IconButton onClick={() => handleRemoveButton(button.id, button.type)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={tAndCChecked}
                  onChange={(e) => setTAndCChecked(e.target.checked)}
                />
                <Typography variant="body2">
                  I understand that it's Bizcollab360 Tech Pvt Ltd's responsibility to stop sending marketing messages to customers who opt out.
                </Typography>
              </Grid>
              {!tAndCChecked && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error">
                    This box must be ticked to add this button.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        );

      case 'custom':
        return (
          <>
            <Divider sx={{ width: '40%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />
            <Grid container spacing={2} sx={{ marginTop: 2, position: 'relative' }} key={button.id}>
              {orderSlider}
              <Grid item xs={4}>
                <TextField
                  label="Type"
                  value="custom"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Button text"
                  value={button.data.buttonText}
                  onChange={(e) => {
                    if (e.target.value === '' || e.target.value.trim().length > 0) {
                      if (e.target.value.length <= 25) {
                        updateButtonData(button.id, { buttonText: e.target.value });
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', right: '10px', top: '12px' }}>
                        {`${button.data.buttonText.length}/25`}
                      </Typography>
                    ),
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid 
                item 
                sx={{ 
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 'auto'
                }}
              >
                <IconButton onClick={() => handleRemoveButton(button.id, button.type)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        );

      case 'visit-website':
        return (
          <>
            <Divider sx={{ width: '40%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />
            <Grid container spacing={2} sx={{ marginTop: 2, position: 'relative' }} key={button.id}>
              {orderSlider}
              <Grid item xs={1.8}>
                <TextField
                  label="Type"
                  value="Visit Website"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Button Text"
                  value={button.data.buttonText}
                  onChange={(e) => {
                    if (e.target.value === '' || e.target.value.trim().length > 0) {
                      if (e.target.value.length <= 25) {
                        updateButtonData(button.id, { buttonText: e.target.value });
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', right: '10px', top: '12px' }}>
                        {`${button.data.buttonText.length}/25`}
                      </Typography>
                    ),
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  value={button.data.urlType}
                  onChange={(e) => {
                    updateButtonData(button.id, { urlType: e.target.value });
                  }}
                  fullWidth
                >
                  <MenuItem value="static">Static</MenuItem>
                  <MenuItem value="dynamic">Dynamic</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="URL"
                  value={button.data.url}
                  onChange={(e) => {
                    if (e.target.value === '' || e.target.value.trim().length > 0) {
                      if (e.target.value.length <= 2000) {
                        updateButtonData(button.id, { url: e.target.value });
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', right: '10px', top: '12px' }}>
                        {`${button.data.url.length}/2000`}
                      </Typography>
                    ),
                  }}
                  error={!isValidURL(button.data.url)}
                  helperText={!isValidURL(button.data.url) ? 'Invalid URL' : ''}
                  fullWidth
                  required
                />
              </Grid>
              {button.data.urlType === 'dynamic' && hasDynamicPlaceholder(button.data.url) && (
                <Grid item xs={8}>
                  <Typography variant="body1" sx={{ display: 'inline-block', marginRight: 1 }}>
                    {'{{1}} :'}
                  </Typography>
                  <TextField
                    sx={{ width: '80%' }}
                    value={button.data.sampleUrl}
                    onChange={(e) => {
                      if (e.target.value === '' || e.target.value.trim().length > 0) {
                        if (e.target.value.length <= 2000) {
                          updateButtonData(button.id, { sampleUrl: e.target.value });
                        }
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', right: '10px', top: '12px' }}>
                          {`${button.data.sampleUrl.length}/2000`}
                        </Typography>
                      ),
                    }}
                    fullWidth={false}
                    required
                  />
                </Grid>
              )}
              <Grid 
                item 
                sx={{ 
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 'auto'
                }}
              >
                <IconButton onClick={() => handleRemoveButton(button.id, button.type)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        );

      case 'call-phone-number':
        return (
          <>
            <Divider sx={{ width: '40%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />
            <Grid container spacing={2} sx={{ marginTop: 2, position: 'relative' }} key={button.id}>
              {orderSlider}
              {/* Phone button fields */}
              <Grid item xs={2.3}>
                <TextField
                  label="Type of function"
                  value="Call Phone Number"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Button Text"
                  value={button.data.buttonText}
                  onChange={(e) => {
                    if (e.target.value === '' || e.target.value.trim().length > 0) {
                      if (e.target.value.length <= 25) {
                        updateButtonData(button.id, { buttonText: e.target.value });
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', right: '10px', top: '12px' }}>
                        {`${button.data.buttonText.length}/25`}
                      </Typography>
                    ),
                  }}
                  fullWidth={false}
                  required
                />
              </Grid>
              <Grid item xs={1.5}>
                <FormControl fullWidth>
                  <InputLabel>Country Code</InputLabel>
                  <Select
                    label="Country Code"
                    value={button.data.countryCode}
                    onChange={(e) => updateButtonData(button.id, { countryCode: e.target.value })}
                    fullWidth
                    required
                  >
                    <MenuItem value="">Select Country Code</MenuItem>
                    <MenuItem value="91">IND (+91)</MenuItem>
                    <MenuItem value="1">US (+1)</MenuItem>
                    <MenuItem value="89">CHN (+89)</MenuItem>
                    <MenuItem value="61">AU (+61)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2.3}>
                <TextField
                  label="Phone Number"
                  value={button.data.phoneNumber}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input === '' || input.trim().length > 0) {
                      if (/^\d*$/.test(input) && input.length <= 20) {
                        updateButtonData(button.id, { phoneNumber: input });
                      }
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant="body2" sx={{ color: 'gray', position: 'absolute', right: '10px', top: '12px' }}>
                        {`${button.data.phoneNumber.length}/20`}
                      </Typography>
                    ),
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid 
                item 
                sx={{ 
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 'auto'
                }}
              >
                <IconButton onClick={() => handleRemoveButton(button.id, button.type)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <ButtonHeader allButtons={allButtons} />

        <Grid item xs={12}>
          <Select
            sx={{ width: '60%' }}
            value={selectedOption}
            onChange={handleOptionChange}
            displayEmpty
            disabled={isButtonLimitReached()}
          >
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>

            <Divider sx={{ width: '100%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />

            <ListSubheader>Quick reply button</ListSubheader>
            {templateType === 'marketing-template' && (
              <MenuItem value="marketing-opt-out" disabled={!canAddButtonType('marketing-opt-out')}>
                Marketing opt-out <span style={{ opacity: 0.6, marginLeft: '5px' }}>({buttonCounts['marketing-opt-out']}/1)</span>
              </MenuItem>
            )}
            <MenuItem value="custom" disabled={!canAddButtonType('custom')}>
              Custom <span style={{ opacity: 0.6, marginLeft: '5px' }}>({buttonCounts['custom']}/10)</span>
            </MenuItem>

            <Divider sx={{ width: '100%', borderColor: 'gray', borderWidth: 1, opacity: 0.2, mt: 2 }} />
            
            <ListSubheader>Call-to-action buttons</ListSubheader>
            <MenuItem value="visit-website" disabled={!canAddButtonType('visit-website')}>
              Visit Website <span style={{ opacity: 0.6, marginLeft: '5px' }}>({buttonCounts['visit-website']}/2)</span>
            </MenuItem>
            <MenuItem value="call-phone-number" disabled={!canAddButtonType('call-phone-number')}>
              Call Phone Number <span style={{ opacity: 0.6, marginLeft: '5px' }}>({buttonCounts['call-phone-number']}/1)</span>
            </MenuItem>
            {/* Not required temporarily - Copy code button */}
            {/* {templateType === 'utility-template' && (
              <MenuItem value="copy-code" disabled={!canAddButtonType('copy-code')}>
                Copy Code <span style={{ opacity: 0.6, marginLeft: '5px' }}>({buttonCounts['copy-code']}/1)</span>
              </MenuItem>
            )} */}
          </Select>
        </Grid>

        {/* Render all buttons using the modular rendering function */}
        {allButtons.map(button => renderButton(button))}
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
      </Grid>
    </>
  );
};

export default ButtonComponent;