import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CustomerSearchInput from "../CustomerSearchInput";
import CustomersTable from './CustomersTable';

function CustomersTab() {
    const [searchValue, setSearchValue] = useState("");
    
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Customers
            </Typography>
          </Grid>
          <Grid item>
            <CustomerSearchInput updateSearchValue={updateSearchValue} />
          </Grid>
        </Grid>
        <CustomersTable searchInput={searchValue} />
      </Container>
    </div>
  );
}

export default CustomersTab;
