import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import AudienceTable from './AudienceTable';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import AudienceFormDialog from './AudienceFormDialog';

const Audience = (props) => {

  return (
    <div className="App">
      <Container maxWidth={false}>
        <>
          <h2 className="heading-title">Audience</h2>
          {/* <Button variant="contained" sx={{ float: 'right', marginRight: 2 }} ><CreateIcon /></Button>
          <Button variant="contained" sx={{ float: 'right', marginRight: 2 }} ><AddIcon /></Button> */}
          <AudienceTable 
            refreshkey={props.refreshKey}
          />
        </>
      </Container>

    </div>
  );
}
export default Audience;
