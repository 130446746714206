import { React, useState } from 'react';
import { Typography, Box, Paper, Card, Avatar, Modal, Backdrop } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import PDFPreview from './PDFPreview';

const MessageNode = (props) => {

    const templateName = props.templateName;
    const language = props.language;
    const headerType = props.headerType;
    const headerText = props.headerText;
    const headerFile = props.headerFile;
    const body = props.body;
    const sampleBodyFields = props.sampleBodyFields;
    const footer = props.footer;
    const buttons = props.buttons;
    const mode = props.mode;
    const allButtons = props.allButtons;

    const [showAllButtons, setShowAllButtons] = useState(false);

    // New function for the slide-up modal
    const createSlideUpModal = ({ open, onClose, buttons }) => {
        if (!open) return null;
        
        return (
            <>
                {/* Backdrop */}
                <Box
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1,
                    }}
                />
                {/* Sliding Content */}
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '450px',
                        maxWidth: '450px',
                        bgcolor: 'background.paper',
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        boxShadow: 24,
                        p: 2,
                        zIndex: 2,
                        animation: 'slideUp 0.3s ease-out',
                        '@keyframes slideUp': {
                            from: { transform: 'translate(-50%, 100%)' },
                            to: { transform: 'translate(-50%, 0)' }
                        }
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        mb: 2,
                        px: 1
                     }}>
                        <Typography variant="h6">Options</Typography>
                        <CloseIcon 
                            onClick={onClose}
                            sx={{ 
                                cursor: 'pointer',
                                '&:hover': { opacity: 0.7 }
                            }}
                        />
                    </Box>
                    
                    <Box sx={{ 
                        maxHeight: '15vh', 
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        px: 1,
                        '::-webkit-scrollbar': {
                            width: '8px'
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                            borderRadius: '4px'
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px',
                            '&:hover': {
                                background: '#555'
                            }
                        }
                    }}>
                        {buttons.map((button, index) => buttonPreview(button))}
                    </Box>
                </Box>
            </>
        );
    }

    function getButtonIcon(type) {
        switch (type) {
            case 'call-phone-number':
                return <PhoneIcon sx={{ fontSize: 16, color: 'primary.main' }} />;
            case 'visit-website':
                return <OpenInNewIcon sx={{ fontSize: 16, color: 'primary.main' }} />;
            case 'marketing-opt-out':
                return <ReplyIcon sx={{ fontSize: 16, color: 'primary.main' }} />;
            case 'custom':
                return <ReplyIcon sx={{ fontSize: 16, color: 'primary.main' }} />;
            default:
                return null;
        }
    }

    function headerPreview(header) {

        const titleStyle = {
            padding: '4px 12px',
            height: '28px',
            background: '#F5F5F5',
            borderRadius: '4px',
            marginBottom: '3px'
        };

        const renderImage = (file) => {
            if (!file) return null;
            let imageUrl;
            if (typeof file === 'string') {
                // If file is a URL, use it directly
                imageUrl = file;
            } else {
                // If file is a file object, convert it to URL
                imageUrl = URL.createObjectURL(file);
            }
            return <img src={imageUrl} width={'220px'} alt="Header preview" />;
        };

        const renderVideo = (videoFile) => {
            if (!videoFile) return null;
            let videoUrl;
            if (typeof videoFile === 'string') {
                // If videoFile is a URL, use it directly
                videoUrl = videoFile;
            } else {
                // If videoFile is a file object, convert it to URL
                videoUrl = URL.createObjectURL(videoFile);
            }
            return (
                <video width="360" controls autoplay muted loop>
                    <source src={videoUrl} type="video/mp4" />
                </video>
            );
        };

        const renderPDF = (pdfFile) => {
            if (!pdfFile) return null;
        
            return <PDFPreview pdfFile={pdfFile} />;
        };

        const renderLocation = () => {
            return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 45">
                        {/* Grey background rectangle */}
                        <rect width="60" height="45" fill="#E0E0E0"/>
                        
                        {/* Location pin - centered and scaled appropriately */}
                        <g transform="translate(22,15)">
                            <path d="M8 1C5.42 1 3.33 3.09 3.33 5.67c0 3.5 4.67 8.66 4.67 8.66s4.67-5.16 4.67-8.66C12.67 3.09 10.58 1 8 1zM8 7.67c-1.1 0-2-0.9-2-2s0.9-2 2-2 2 0.9 2 2-0.9 2-2 2z" 
                                fill="#FF0000" 
                                transform="scale(1)"/>
                        </g>
                    </svg>
                </>
            )
        };

        return (
            <>
                {header.format === 'text' ?
                    <Typography sx={{ fontWeight: 'bold' }}>{header.str}</Typography>
                    : header.format === 'image' ?
                        renderImage(headerFile)
                        : header.format === 'video' ?
                            renderVideo(headerFile)
                            : header.format === 'document' ?
                                renderPDF(headerFile)
                                : header.format === 'location' ?
                                    renderLocation()
                                    : null}
            </>
        );
    }

    function bodyPreview(text) {
        return (
            <>
                <Typography
                    sx={{ whiteSpace: 'pre-wrap' }}
                >
                    {text}
                </Typography>
            </>
        );
    }

    function footerPreview(text) {
        return (
            <>
                <Typography sx={{ fontWeight: 300, color: 'gray' }}>{text}</Typography>
            </>
        );
    }

    function buttonPreview(buttonData) {
        const buttonStyle = {
            width: '380px',
            height: '30px',
            minHeight: '30px',
            marginTop: '8px',
            backgroundColor: '#FFFFFF',
            padding: '5.34103px 0px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px'
        };
        return (
            <Card 
                variant='elevation' 
                sx={buttonStyle}
            >
                {getButtonIcon(buttonData.type)}
                <Typography 
                    align='center' 
                    color={'#007DFF'} 
                    fontSize={'14px'} 
                    fontWeight={400}
                >
                    {buttonData.data.buttonText}
                </Typography>
            </Card>
        );
    }

    return (
        <>
            <Paper variant="outlined" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '20px',
                width: '500px',
                border: '2px solid #007DFF',
                background: '#FFFFFF',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.18)',
                borderRadius: '8px',
                height: 'fit-content',
                position: 'sticky',
                top: '120px',
                maxHeight: '90vh',
                overflowY: 'auto'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: "center",
                    gap: '8px',
                    marginBottom: '14px'
                }}>
                    {mode !== 'view' && (
                        <>
                            <Avatar sx={{ bgcolor: '#7986CB', height: '60px', width: '60px', padding: '10px', gap: '10px' }}>
                                <MessageIcon sx={{ height: '30px', width: '30px' }} />
                            </Avatar>
                            <Typography fontSize={20} fontWeight={500} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                Template Preview
                            </Typography>
                        </>
                    )}
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: 'center',
                    width: '450px',
                    backgroundColor: '#F5F5F5',
                    borderRadius: '8px',
                    padding: '20px'
                }}>
                    <Card variant='elevation' sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '380px',
                        backgroundColor: '#FFFFFF',
                        padding: '7.12px'
                    }}>
                        {headerType !== 'none' ?
                            <>
                                {headerPreview({ format: headerType, str: headerText })}
                            </>
                            : null}
                        {body !== '' ? bodyPreview(body) : null}
                        {footer !== '' ?
                            <>
                                {footerPreview(footer)}
                            </>
                            : null}
                    </Card>
                    
                    {/* button rendering logic */}
                    {/* {allButtons && allButtons.slice(0, 10).map(button => buttonPreview(button))} */}

                    {/* Modified button rendering logic */}
                    {allButtons && allButtons.length > 0 && (
                        <>
                            {/* Show first 3 buttons */}
                            {allButtons.slice(0, 3).map(button => buttonPreview(button))}
                            
                            {/* If more than 3 buttons exist, show 'See all options' */}
                            {allButtons.length > 3 && (
                                <>
                                    <Card 
                                        variant='elevation' 
                                        onClick={() => setShowAllButtons(true)}
                                        sx={{
                                            width: '380px',
                                            height: '30px',
                                            marginTop: '8px',
                                            backgroundColor: '#FFFFFF',
                                            padding: '5.34103px 0px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '8px',
                                            '&:hover': {
                                                backgroundColor: '#F5F5F5'
                                            }
                                        }}
                                    >
                                        <ListIcon sx={{ fontSize: 20, color: 'primary.main' }}></ListIcon>
                                        <Typography 
                                            align='center' 
                                            color={'#007DFF'} 
                                            fontSize={'14px'} 
                                            fontWeight={400}
                                        >
                                            See all options
                                        </Typography>
                                    </Card>
                                </>
                            )}
                        </>
                    )}

                    {/* Slide-up modal */}
                    {createSlideUpModal({ 
                        open: showAllButtons,
                        onClose: () => setShowAllButtons(false),
                        buttons: allButtons || []
                    })}
                </Box>
            </Paper>
        </>
    );
}

export default MessageNode;