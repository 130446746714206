import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Collapse,
  Alert,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import {
  SUBZONE_CREATE_ENDPOINT,
  SUBZONE_EDIT_ENDPOINT,
  ZONES_ENDPOINT,
  ZONE_EDIT_ENDPOINT,
  ZONE_CREATE_ENDPOINT,
  SUBZONES_ENDPOINT // Assuming this endpoint exists to fetch all subzones
} from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';
import debounce from 'lodash.debounce'; // Ensure lodash.debounce is installed

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const SubzoneFormDialog = ({ modalState, handleModalState, selectedClient, refreshTable }) => {
  const [formType, setFormType] = useState('subzone'); // 'zone' or 'subzone'

  const { getValidToken } = useAuth(); 
  const [alertObj, setAlertObj] = useState({ active: false, msg: "", type: "" });

  const [formData, setFormData] = useState({
    name: '',
    zone: '', 
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    zone: '', 
  });

  const [zones, setZones] = useState([]);
  const [subzones, setSubzones] = useState([]); // To store existing subzones for validation

  const [isSubzoneValid, setIsSubzoneValid] = useState(false); // Track subzone name validity

  const handleAlertClose = () => setAlertObj({ active: false, type: "", msg: "" });

  const handleZoneBtnClick = (zoneType) => {
    setFormType(zoneType);
    setFormErrors({ name: '', zone: '' });
    setFormData({ name: '', zone: '' });
    setIsSubzoneValid(false);
  };

  const handleClose = () => {
    handleModalState({ isOpen: false });
    setFormErrors({ name: '', zone: '' });
    setFormData({ name: '', zone: '' });
    setIsSubzoneValid(false);
    setAlertObj({ active: false, type: "", msg: "" });
    setFormType("subzone");
  };

  // Fetch zones and subzones using Fetch API
  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await fetch(ZONES_ENDPOINT);
        const data = await response.json();
        setZones(data.results);
      } catch (error) {
        console.error('Failed to fetch zones:', error);
      }
    };

    const fetchSubzones = async () => {
      try {
        const response = await fetch(SUBZONES_ENDPOINT);
        const data = await response.json();
        setSubzones(data.results);
      } catch (error) {
        console.error('Failed to fetch subzones:', error);
      }
    };

    fetchZones();
    fetchSubzones();
  }, [formType]);

  useEffect(() => {
    if (selectedClient) {
      setFormData({
        name: selectedClient.name || '',
        zone: selectedClient.zone?.id || '',
      });
      setFormType("subzone");
      if (formType === 'subzone') {
        setIsSubzoneValid(true); // Assume existing subzone is valid for editing
      }
    } else {
      setFormData({ name: '', zone: '' });
      setIsSubzoneValid(false);
    }
    setFormErrors({ name: '', zone: '' });
  }, [selectedClient, formType]);

  // Debounced validation function
  const validateSubzoneName = useCallback(debounce(async (name) => {
    if (formType !== 'subzone') {
      setIsSubzoneValid(true); // Not validating for zones
      return;
    }

    if (!name.trim()) {
      setFormErrors(prev => ({ ...prev, name: 'Please enter a name' }));
      setIsSubzoneValid(false);
      return;
    }

    try {
      // Check against the fetched subzones
      const exists = subzones.some(
        (subzone) => subzone.name.toLowerCase() === name.trim().toLowerCase() && subzone.id !== (selectedClient?.id || null)
      );

      if (exists) {
        setFormErrors(prev => ({ ...prev, name: 'This Subzone already exists.' }));
        setIsSubzoneValid(false);
      } else {
        setFormErrors(prev => ({ ...prev, name: '' }));
        setIsSubzoneValid(true);
      }
    } catch (error) {
      console.error('Failed to validate subzone name:', error);
      setFormErrors(prev => ({ ...prev, name: 'Validation failed. Please try again.' }));
      setIsSubzoneValid(false);
    }
  }, 500), [subzones, formType, selectedClient]);

  // Handle changes in the form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'name' && formType === 'subzone') {
      setIsSubzoneValid(false);
      validateSubzoneName(value);
    }
    // No additional validation required for the 'zone' field as it's a select input
  };

  const validateForm = () => {
    const newFormErrors = {};
    let hasErrors = false;

    if (!formData.name.trim()) {
      newFormErrors.name = 'Please enter a name';
      hasErrors = true;
    }

    if (formType === 'subzone' && !formData.zone) {  
      newFormErrors.zone = 'Please select a zone';
      hasErrors = true;
    }

    setFormErrors(newFormErrors);
    return !hasErrors;
  };

  const handleAdd = async () => {
    if (!validateForm()) return;

    try {
      const token = await getValidToken();
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
    
      let url =  formType === "subzone" ? SUBZONE_CREATE_ENDPOINT : ZONE_CREATE_ENDPOINT;
      let payload = {};

      // Prepare data to send
      if (formType === "subzone"){
        payload = {
            name: formData.name,
            zone: formData.zone
        };

      }
      else {
        payload = {
            name: formData.name,
        };
      }

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      });

      
      if (response.ok) {
        setAlertObj({ active: true, type: "success", msg: `${formType === 'subzone' ? "Subzone" : "Zone"} created successfully` });
        refreshTable(prev => prev + 1);
        setTimeout(handleClose, 3000); // Auto-close after 3 seconds
      } else {
        const errorData = await response.json();
        console.log(errorData);
        // Check if the error is due to uniqueness
        if (errorData.name && errorData.name[0].toLowerCase().includes('unique')) {
          setFormErrors(prevErrors => ({
            ...prevErrors,
            name: `This ${formType === 'subzone' ? 'Subzone' : 'Zone'} already exists.`,
            zone: errorData.zone || '',
          }));
        } else {
          setFormErrors(prevErrors => ({
            ...prevErrors,
            name: errorData.name || '',
            zone: errorData.zone || '',
          }));
        }
        setAlertObj({ active: true, type: "error", msg: `Failed to create ${formType === 'subzone' ? "Subzone" : "Zone"}` });
      }
    } catch (error) {
      console.error(`Failed to create ${formType === 'subzone' ? "subzone" : "zone"}:`, error);
      
      formType === "zone" ? setAlertObj({ active: true, type: "error", msg: `${error}` }) :
      setAlertObj({ active: true, type: "error", msg: `Failed to create ${formType === 'subzone' ? "Subzone" : "Zone"}` });
    }
  };

  const handleEdit = async () => {
    if (!validateForm()) return;

    try {
      const token = await getValidToken();
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      // Prepare data to send
      const payload = {
        name: formData.name,
      };

      if (formType === "subzone") {
        payload.zone = formData.zone;
      }
    
      const endpoint = formType === 'subzone' ? SUBZONE_EDIT_ENDPOINT : ZONE_EDIT_ENDPOINT;
      const response = await fetch(`${endpoint}${selectedClient.id}/`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setAlertObj({ active: true, type: "success", msg: `${formType === 'subzone' ? "Subzone" : "Zone"} updated successfully` });
        refreshTable(prev => prev + 1);
        setTimeout(handleClose, 3000); // Auto-close after 3 seconds
      } else {
        const errorData = await response.json();
        // Check if the error is due to uniqueness
        if (errorData.name && errorData.name.toLowerCase().includes('unique')) {
          setFormErrors(prevErrors => ({
            ...prevErrors,
            name: `This ${formType === 'subzone' ? 'Subzone' : 'Zone'} already exists.`,
            zone: errorData.zone || '',
          }));
        } else {
          setFormErrors(prevErrors => ({
            ...prevErrors,
            name: errorData.name || '',
            zone: errorData.zone || '',
          }));
        }
        setAlertObj({ active: true, type: "error", msg: `Failed to update ${formType === 'subzone' ? "Subzone" : "Zone"}` });
      }
    } catch (error) {
      console.error(`Failed to update ${formType === 'subzone' ? "subzone" : "zone"}:`, error);
      setAlertObj({ active: true, type: "error", msg: `Failed to update ${formType === 'subzone' ? "Subzone" : "Zone"}` });
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {formType === "subzone" ? (selectedClient ? "Edit Subzone" : "Add Subzone") : (selectedClient ? "Edit Zone" : "Add Zone")}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert severity={alertObj.type} onClose={handleAlertClose}>
            {alertObj.msg}
          </Alert>
        </Collapse>
        <Box sx={{ m: 3 }}>
          <TextField
            fullWidth
            margin="normal"
            label={formType === "subzone" ? "Subzone" : "Zone"}
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
            disabled={selectedClient}
            required
          />
          { formType === "subzone" ? (
            <FormControl
              fullWidth
              margin="normal"
              error={!!formErrors.zone}
              disabled={formType === "zone" ? false : false} // Adjusted as per formType
            >
              <InputLabel id="zone-select-label">Zone</InputLabel>
              <Select
                labelId="zone-select-label"
                name="zone"
                value={formData.zone}
                onChange={handleChange}
                label="Zone"
                disabled={formType === 'subzone' && !isSubzoneValid && !selectedClient}
                required
              >
                {zones.map(zone => (
                  <MenuItem key={zone.id} value={zone.id}>{zone.name}</MenuItem>
                ))}
              </Select>
              {formErrors.zone && <FormHelperText>{formErrors.zone}</FormHelperText>}
            </FormControl>
          ) : null}
          { 
            selectedClient ? null : 
                (
                    formType === "zone" ? 
                    <Button autoFocus onClick={() => handleZoneBtnClick('subzone')} sx={{ float: 'right' }}>Create Subzone</Button> 
                    : <Button autoFocus onClick={() => handleZoneBtnClick('zone')} sx={{ float: 'right' }}>Create Zone</Button>
                ) 
            }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>Cancel</Button>
        <Button 
          onClick={selectedClient ? handleEdit : handleAdd} 
          disabled={formType === 'subzone' && !isSubzoneValid && !selectedClient}
          variant="contained"
          color="primary"
        >
          {selectedClient ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default SubzoneFormDialog;
