import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DriverSearchInput from "./SearchInput";


import DriverOrderReportTable from './ReportTable';

function OrdersTab() {
    const [searchValue, setSearchValue] = useState("");
    
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Order Requests By Driver
            </Typography>
          </Grid>
          <Grid item>
          <DriverSearchInput updateSearchValue={updateSearchValue} />
          </Grid>
        </Grid>
        <DriverOrderReportTable searchInput={searchValue} />
      </Container>
    </div>
  );
}

export default OrdersTab;
