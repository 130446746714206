import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Collapse,
  Alert,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { DRIVERS_CREATE_ENDPOINT, DRIVERS_EDIT_ENDPOINT, ZONES_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider'; 
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DriverFormDialog = ({ modalState, handleModalState, selectedClient, refreshTable }) => {
  const { getValidToken } = useAuth(); 
  const [alertObj, setAlertObj] = useState({ active: false, msg: "", type: "" });
  const [formData, setFormData] = useState({
    name: '',
    phone_no: '',
    email: '',
    date_of_birth: null,
    zone: '', 
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    phone_no: '',
    email: '',
    date_of_birth: '',
    zone: '', 
  });

  const [zones, setZones] = useState([]); 

  const handleAlertClose = () => setAlertObj({ active: false, type: "", msg: "" });

  const handleClose = () => {
    handleModalState({ isOpen: false });
    setFormErrors({ name: '', phone_no: '', email: '', date_of_birth: '', zone: '' });
    setFormData({ name: '', phone_no: '', email: '', date_of_birth: null, zone: '' });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date_of_birth: date });
  };

  // Fetch zones from API
  useEffect(() => {
    axios.get(ZONES_ENDPOINT)
      .then(response => setZones(response.data.results))
      .catch(error => console.error('Failed to fetch zones:', error));
  }, []);

  useEffect(() => {
    if (selectedClient) {
      setFormData({
        name: selectedClient.name,
        phone_no: selectedClient.phone_no,
        email: selectedClient.email,
        date_of_birth: selectedClient.date_of_birth ? dayjs(selectedClient.date_of_birth) : null,
        zone: selectedClient.zone?.id || '', 
      });
    } else {
      setFormData({ name: '', phone_no: '', email: '', date_of_birth: null, zone: '' });
    }
  }, [selectedClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newFormErrors = {};
    let hasErrors = false;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!formData.name.trim()) {
      newFormErrors.name = 'Please enter a name';
      hasErrors = true;
    }
    if (!formData.phone_no.trim()) {
      newFormErrors.phone_no = 'Please enter a phone number';
      hasErrors = true;
    }
    if (!formData.email.trim() || !emailPattern.test(formData.email.trim())) {
      newFormErrors.email = 'Please enter a valid email';
      hasErrors = true;
    }
    if (!formData.date_of_birth) {
      newFormErrors.date_of_birth = 'Please select a date of birth';
      hasErrors = true;
    }
    // if (!formData.zone) {  
    //   newFormErrors.zone = 'Please select a zone';
    //   hasErrors = true;
    // }

    setFormErrors(newFormErrors);
    return !hasErrors;
  };

  const handleAdd = async () => {
    if (!validateForm()) return;

    const token = await getValidToken(); 
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };

    // Convert date_of_birth to YYYY-MM-DD format
    const formattedData = {
      ...formData,
      date_of_birth: formData.date_of_birth ? dayjs(formData.date_of_birth).format('YYYY-MM-DD') : null,
    };

    axios.post(DRIVERS_CREATE_ENDPOINT, formattedData, { headers })
      .then(response => {
        setAlertObj({ active: true, type: "success", msg: "Driver created successfully" });
        refreshTable(key => key + 1);
      })
      .catch(error => {
        const errorResponse = error.response?.data || {};
        setFormErrors({
          name: errorResponse.name || '',
          phone_no: errorResponse.phone_no || '',
          email: errorResponse.email || '',
          date_of_birth: errorResponse.date_of_birth || '',
          zone: errorResponse.zone || '',
        });
        setAlertObj({ active: true, type: "error", msg: "Failed to create driver" });
      });
  };

  const handleEdit = async () => {
    if (!validateForm()) return;

    const token = await getValidToken(); 
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };

    // Convert date_of_birth to YYYY-MM-DD format
    const formattedData = {
      ...formData,
      date_of_birth: formData.date_of_birth ? dayjs(formData.date_of_birth).format('YYYY-MM-DD') : null,
    };

    axios.patch(`${DRIVERS_EDIT_ENDPOINT}${selectedClient.id}/`, formattedData, { headers })
      .then(response => {
        setAlertObj({ active: true, type: "success", msg: "Driver updated successfully" });
        refreshTable(key => key + 1);
      })
      .catch(error => {
        const errorResponse = error.response?.data || {};
        setFormErrors({
          name: errorResponse.name || '',
          phone_no: errorResponse.phone_no || '',
          email: errorResponse.email || '',
          date_of_birth: errorResponse.date_of_birth || '',
          zone: errorResponse.zone || '',
        });
        setAlertObj({ active: true, type: "error", msg: "Failed to update driver" });
      });
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }}>
        {modalState.mode === 'edit' ? 'Edit Driver' : 'Create Driver'}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert severity={alertObj.type} onClose={handleAlertClose}>{alertObj.msg}</Alert>
        </Collapse>
        <Box sx={{ m: 3 }}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
            required={true}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Phone Number"
            name="phone_no"
            value={formData.phone_no}
            onChange={handleChange}
            error={!!formErrors.phone_no}
            helperText={formErrors.phone_no}
            required={true}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            required={true}
          />
          <FormControl fullWidth margin="normal" error={!!formErrors.date_of_birth}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                label="Date of Birth"
                value={formData.date_of_birth}
                format="DD/MM/YYYY"
                onChange={handleDateChange}
                disableFuture
                renderInput={(params) => <TextField {...params} />}
                clearable
              />
            </LocalizationProvider>
            <FormHelperText>{formErrors.date_of_birth}</FormHelperText>
          </FormControl>
          {/* <FormControl fullWidth margin="normal" error={!!formErrors.zone}>
            <InputLabel id="zone-select-label">Zone</InputLabel>
            <Select
              labelId="zone-select-label"
              name="zone"
              value={formData.zone}
              onChange={handleChange}
              label="Zone"
              required={true}
            >
              {zones.map(zone => (
                <MenuItem key={zone.id} value={zone.id}>{zone.name}</MenuItem>
              ))}
            </Select>
            {formErrors.zone && <FormHelperText>{formErrors.zone}</FormHelperText>}
          </FormControl> */}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {modalState.mode === 'edit' ? (
          <Button variant="contained" onClick={handleEdit}>Edit</Button>
        ) : (
          <Button variant="contained" onClick={handleAdd}>Create</Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DriverFormDialog;

